<script>
    export default {
        SiteVisitChart: {
            series: [
                {
                    name: "Dispositivos",
                    data: [0, 0, 0],
                },
            ],
            chartOptions: {
                colors: ["#1e88e5"],
                chart: {
                    // fontFamily: "Montserrat,sans-serif",
                    toolbar: {
                        show: false,
                    },
                    sparkline: {
                        enabled: false,
                    },
                },
                grid: {
                    borderColor: "rgba(0, 0, 0, .1)",
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 4,
                    strokeColors: "transparent",
                },
                legend: {
                    show: false,
                },
                xaxis: {
                    type: "category",
                    categories: [
                        "Móviles",
                        "Tablets",
                        "Escritorios",
                    ],
                    labels: {
                        style: {
                            cssClass: "grey--text lighten-2--text fill-color",
                        },
                    },
                },
                yaxis: {
                    show: true,
                    labels: {
                        style: {
                            cssClass: "grey--text lighten-2--text fill-color",
                        },
                    },
                },
                stroke: {
                    curve: "straight",
                    width: 3,
                },
                tooltip: {
                    theme: "dark",
                },
            },
        }
    };
</script>